<template>
    <div>
        <div v-for="(item, id) in options" :key="id" :class="['custom-control', { 'custom-control-inline': inline }, 'custom-checkbox', 'mb-1']">
            <input type="checkbox" class="custom-control-input" :value="value" :checked="value[id]" @change="handleInput" :id="'ve-cb-' + id">
            <label class="custom-control-label custom-checkbox-label" :for="'ve-cb-' + id">{{ item.label }}</label>
        </div>
        <div v-show="validation_error" class="custom-invalid-feedback">{{ __("validation.checkbox") }}</div>
    </div>
</template>

<script>

export default {
    name: "ve-custom-checkbox",
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },
        options: {
            type: Object,
            default: {}
        },
        rules: {
            type: String,
            default: ""
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
        handleInput(e) {

            let id = e.srcElement.id.split('ve-cb-')[1]

            this.value[id] = e.srcElement.checked ? true : false

            this.$emit('input', this.value)

            this.performValidation()

            this.$forceUpdate()

        },

        performValidation() {
            if(this.rules.includes('required')) {
                let ok = false

                for(let key in this.value)
                    if(this.value[key]) ok = true

                for(let key in this.value) {
                    if(!ok) {
                        document.getElementById(`ve-cb-${key}`).setCustomValidity("Select at least one of the checkboxes")
                        this.validation_error = "Select at least one of the checkboxes"
                    } else {
                        document.getElementById(`ve-cb-${key}`).setCustomValidity("")
                        this.validation_error = ""
                    }  
                }
            }
        }
    },
    created() {
        for(let option in this.options)
            if(!this.value[option])
                this.value[option] = false
        this.$emit('input', this.value)

        this.$nextTick(() => {
            this.performValidation()
        })
    }
}
</script>

<style lang="scss" scoped>


</style>
